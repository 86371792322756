const deepLinkStorageKey = 'deepLink';

const storeDeepLink = () => {
  const currentLocation = window.location.href;
  localStorage.setItem(deepLinkStorageKey, currentLocation);
};

const consumeDeepLink = () => {
  const storedValue = localStorage.getItem(deepLinkStorageKey);
  localStorage.removeItem(deepLinkStorageKey);
  return storedValue;
};

export { consumeDeepLink, storeDeepLink };
