import styled from '@emotion/styled/macro';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { SideNav } from 'components/organisms/SideNav';
import { maxWidth } from 'helpers/styleConstants';

type props = { children: React.ReactNode };

const SideNavWithContent = ({ children }: props) => {
  const { pathname } = useLocation();
  const hideNav = pathname === '/customers/create';
  const isMobile = useMediaQuery({ maxWidth: maxWidth });

  return (
    <SideNavWithContentContainer isMobile={isMobile}>
      {!hideNav && <SideNav></SideNav>}
      {children}
    </SideNavWithContentContainer>
  );
};

const SideNavWithContentContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  & :first {
    width: 100%;
  }
  height: 100vh;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  background-color: #fffbf0;
`;

export { SideNavWithContent };
